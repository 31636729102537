const React = require('react');
const PropTypes = require('prop-types');

const Oops = require('../Oops');
const IconOops = require('../icons/Oops');
const { ErrorScreen } = require('@andes/technical-error');
const Error404Illustration = require('../icons/Error404Illustration');

const { injectI18n } = require('nordic/i18n');
const translate = require('../../translation');

// Constants
const { ERROR_TYPES } = require('../../../constants/app');

const ExceptionError = (props) => {
  const { i18n } = props;
  const translations = translate(i18n);

  return (
    <>
      {props.errorType === ERROR_TYPES.ERROR_PREFERENCE ? (
        <ErrorScreen
          className="controlled-error"
          asset={<Error404Illustration />}
          description={translations.DESCRIPTION_CAN_NOT_FIND_THE_PAGE_ERROR}
          title={translations.CAN_NOT_FIND_THE_PAGE}
        />
      ) : (
        <Oops message={translations.OH_NO_SOMETHING_WENT_WRONG} errorCode={props.errorCode} isRebranding={props.isRebranding}>
          <IconOops />
        </Oops>
      )}
    </>
  );
};

ExceptionError.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  errorCode: PropTypes.string,
  errorType: PropTypes.string,
};

ExceptionError.defaultProps = {
  i18n: {
    gettext: (t) => t,
  },
  errorCode: '',
  errorType: '',
};

module.exports = injectI18n(ExceptionError);
